/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-col w-full">
        <vx-card class="mb-base">
          <h3 class="font-bold mb-4">Controls</h3>
          <vue-draggable-container id="wordBank" class="wordBank grid grid-cols-5 min-h-0">
            <h5 class="dragBlock font-semibold cursor-pointer h-fit" v-for="block in dragBlocks">{{ block }}</h5>
          </vue-draggable-container>
        </vx-card>
      </div>
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-7/12 md:w-7/12 sm:w-5/5">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/ccna/243725849601837204/image.png"
            class="w-full rounded-lg main-border"
          />
        </div>
        <div class="vx-col lg:w-5/12 md:w-5/12 sm:w-5/5">
          <div class="grid grid-cols-2 gap-5">
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">10.10.13.0</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input0" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">10.10.13.144</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input1" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">10.10.13.160</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input2" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">209.165.202.128</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input3" />
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      dragBlocks: ['255.255.255.128', '255.255.255.224', '255.255.255.240', '255.255.255.248', '255.255.255.252'],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let total_score = 0;

      if (document.getElementById('input0').textContent === '255.255.255.128') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === '255.255.255.240') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === '255.255.255.248') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === '255.255.255.224') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      }

      this.$emit('simulation_getting_there', total_score);
    },
  },
  created() {
    this.dragBlocks = shuffle(this.dragBlocks);
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
}
</style>
